@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'SF UI Display', Arial, sans-serif;
}
.custom-button {
  width: 160px;
  height: 40px;
  left: 403px;
  top: 549px;
  background: linear-gradient(99.34deg, #2137ff -26.28%, #ff1df6 147.09%);
  border-radius: 6px;
  color: white;
}
/* .custom-margin {
  margin-left: 100px;
} */
/* .custom-width {
  width: 250px;
} */
.bgColor {
  background: rgba(47, 68, 255, 0.1);
  border-radius: 24px;
}
.centerText {
  padding-top: 50px;
  padding-bottom: 50px;
}
.boxShadow {
  border-radius: 24px;
  box-shadow: 0px 40px 100px rgba(0, 0, 0, 0.1);
}
.rightBox {
  top: 90px;
}
.bottomLeftBox {
  bottom: -50px;
}

.bottomRightBox {
  bottom: -80px;
}
.bottomZero {
  bottom: 0;
}
.carrerBg {
  background-color: rgba(47, 68, 255, 0.1);
}
.textColor {
  color: #2f44ff;
}
